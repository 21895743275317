import styled from 'styled-components'
import { media } from 'themes'

export const Wrapper = styled.footer`
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #CBCBCB;
  margin-top: 150px;

  ${media.sm`
    margin-top: 100px;
    margin-bottom: 64px;
  `}

  ${({ homepage }) => homepage && `
    opacity: 0.4;
    color: #ffffff;
    margin-top: 0;

    ${Link} {
      color: white;
    }
  `}
`

export const Link = styled.a`
  text-decoration: none;
  color: #CBCBCB;

  &:hover {
    text-decoration: underline;
  }
`

export const Icon = styled.img`
  display: block;
  margin: 0 auto 80px auto;
`

export const Bottom = styled.span`
  ${media.sm`
    display: block;
  `}
`