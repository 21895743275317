import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { media } from 'themes'

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 32px;
`

export const Logo = styled(GatsbyLink).attrs({
  to: '/',
})`
  font-family: 'Playfair Display';
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #000;

  ${media.sm`
    font-size: 24px;
  `}

  ${({ white }) => white && `
    color: #FFF;
  `}
`

export const Menu = styled.div`
  margin-top: 40px;

  ${media.sm`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    margin: 0;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;

    ${({ open }) => open && `
      display: flex;
      z-index: 9;
    `}
  `}

  a {
    color: #000;

    ${({ white }) => white && `
      color: #FFF;
    `}

    ${media.sm`
      color: black;
    `}
  }
`

export const Link = styled(GatsbyLink).attrs({
  activeClassName: 'active'
})`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  transition: color 200ms;

  &.active {
    color: #C74513;
  }

  &:hover {
    text-decoration: underline;

    ${media.sm`
      text-decoration: none;
    `}
  }

  ${media.sm`
    margin-bottom: 48px;
    font-size: 22px;
  `}

  & + & {
    margin-left: 90px;

    ${media.sm`
      margin-left: 0;
    `}
  }
`

export const Hamburger = styled.div`
  position: absolute;
  top: 88px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  z-index: 100;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  ${media.sm`
    display: flex;
  `}

  .hamburger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }

  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #000000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    ${({ white }) => white && `
      background-color: #FFFFFF;
    `}
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }

  .hamburger--slider .hamburger-inner {
    top: 0px;
  }
  .hamburger--slider .hamburger-inner::before {
    top: 9px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }
  .hamburger--slider .hamburger-inner::after {
    top: 18px;
  }

  .hamburger--slider.is-active .hamburger-inner {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -9px, 0);
    opacity: 0;
  }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -18px, 0) rotate(-90deg);
  }

  .hamburger--slider.is-active {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      ${({ white }) => white && `
        background-color: #000000;
      `}
    }
  }
`
