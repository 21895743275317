import React from 'react'
import FileIconScale from 'images/icon-scale.svg'
import { Wrapper, Link, Icon, Bottom } from './styled'

const Footer = ({ homepage }) => (
  <Wrapper homepage={homepage}>
    {!homepage && <Icon src={FileIconScale} />}
    Copyright &copy;Robert W. Sadowski PLLC.
    
    <Bottom>
      All rights reserved.&nbsp;
      <Link href="https://www.innovatica.com" target="_blank">
        Crafted by Innovatica
      </Link>
      .
    </Bottom>
  </Wrapper>
)

export default Footer
