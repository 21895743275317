import React, { Component } from 'react'
import { Wrapper, Hamburger, Logo, Menu, Link } from './styled'

class Navbar extends Component {
  state = {
    open: false,
  }

  handleToggleMenu = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  render() {
    const { homepage } = this.props;

    return (
      <Wrapper>
        <Logo white={homepage ? 1 : 0}>Robert W. Sadowski PLLC</Logo>

        <Menu white={homepage ? 1 : 0} open={this.state.open}>
          <Link to="/areas">Practice Areas</Link>
          <Link to="/about">Attorneys</Link>
          <Link to="/news">News</Link>
          <Link to="/contact">Contact Us</Link>
        </Menu>

        <Hamburger white={homepage ? 1 : 0} onClick={this.handleToggleMenu}>
          <div
            className={`hamburger hamburger--slider ${
              this.state.open ? 'is-active' : ''
            }`}
          >
            <div className="hamburger-box">
              <div className="hamburger-inner" />
            </div>
          </div>
        </Hamburger>
      </Wrapper>
    )
  }
}

export default Navbar
